import React, { useState, useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import { GoogleMap, LoadScript, Marker, InfoWindow, } from "@react-google-maps/api";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import Pin from "../../assets/img/svg/pin.svg";
import LocationArrow from "../../assets/img/svg/loc-arrow.svg";

import Geocode from "react-geocode";

const CAUNetworksMap = () => {
  const theme = useContext(ThemeContext);

  const [selectedNet, setSelectedNet] = useState(null);
  const [address, setAddress] = useState(null);
  const [markerMap, setMarkerMap] = useState({});
  const [zoom, setZoom] = useState(5);
  const [infoOpen, setInfoOpen] = useState(false);
  const [center, setCenter] = useState({ lat: 42.94684384549533, lng: 12.675132971462103, });

  // We have to create a mapping of our nets to actual Marker objects
  const markerLoadHandler = (marker, net) => {
    return setMarkerMap((prevState) => {
      return { ...prevState, [net.id]: marker };
    });
  };

  // Google Geocode
  Geocode.setApiKey("AIzaSyC4ArX67xicuRaF0rxw8BMnigEIVUcr1IQ");
  Geocode.setLanguage("it");
  Geocode.setLocationType("ROOFTOP");
  
  const markerClickHandler = (net) => {
    // Remember which net was clicked
    setSelectedNet(net);

    // Required so clicking a 2nd marker works as expected
    if (infoOpen) {
      setInfoOpen(false);
    }

    setInfoOpen(true);

    // If you want to zoom in a little on marker click
    if (zoom < 13) {
      setZoom(13);
    }

    // if you want to center the selected Marker
    setCenter({ lat: parseFloat(net.lat), lng: parseFloat(net.long) });

    // Find address with Google Geocode
    Geocode.fromLatLng(net.lat, net.long).then(
      (response) => {
        setAddress(response.results[0].formatted_address);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  SwiperCore.use([Autoplay]);

  return (
    <>
      <Swiper
        spaceBetween={0}
        slidesPerView={theme.isMobile ? 1 : 6}
        slideToClickedSlide={true}
        centeredSlides={true}
        autoplay={{ delay: 2500, disableOnInteraction: false }}
        loop={true}
      >
        {theme.NETWORKS.filter((i) => i.featured).map((i) => (
          <SwiperSlide key={i.id} onClick={() => markerClickHandler(i)}>
            <div className="netLogo">
              <img src={i.logo} alt={i.name} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="container">
        <LoadScript googleMapsApiKey="AIzaSyC4ArX67xicuRaF0rxw8BMnigEIVUcr1IQ">
          <GoogleMap
            mapContainerStyle={{
              height: "70vh",
              width: "100%",
            }}
            center={center}
            zoom={zoom}
          >
            {theme.NETWORKS.map((i) => (
              <Marker
                key={i.id}
                position={{ lat: parseFloat(i.lat), lng: parseFloat(i.long) }}
                onLoad={(marker) => markerLoadHandler(marker, i)}
                onClick={() => markerClickHandler(i)}
                icon={Pin}
              />
            ))}

            {infoOpen && selectedNet && (
              <InfoWindow
                anchor={markerMap[selectedNet.id]}
                onCloseClick={() => setInfoOpen(false)}
              >
                <div>
                  <span className="azure">
                    {selectedNet.name}&nbsp;
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`https://www.google.com/maps?q=${selectedNet.lat},${selectedNet.long}`}
                    >
                      <img src={LocationArrow} alt="Location Arrow" />
                    </a>
                  </span>
                  <div>{address}</div>
                </div>
              </InfoWindow>
            )}
          </GoogleMap>
        </LoadScript>
      </div>
    </>
  );
};

export default CAUNetworksMap;
