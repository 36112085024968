import React from "react";
import Header from "../components/header/header.js";
import CAUNetworksMap from "../components/cauNetworksMap/cauNetworksMap.js";

import "../assets/styles/pages/_our-customers.scss";

const OurCustomers = () => {
  
  return (
    <>
      <Header title="I nostri clienti" />
      <div className="our-customers">
        <CAUNetworksMap />
        <div className="section" />
      </div>
    </>
  );
};

export default OurCustomers;
